import { format } from "date-fns";
import { FormikHelpers, useFormik } from "formik";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { PriorityType } from "src/backend";
import { QUERY_PARAM_FORM_ELEMENT_ID } from "src/constants/query-params";
import { useGetElements } from "src/hooks/use-get-elements";
import { useUser } from "src/hooks/use-user";
import { useCreateElementsMutation, useCreateSharedInfoElementMutation, useGetLoanElementsQuery, useUpdateElementsMutation } from "src/services/packageApi";
import { getLoanFormElements } from "src/slices/form-element";
import { useDispatch } from "src/store";
import { findSectionByIdAndItsAncestors } from "src/utils";
import { filterPriority, sortPriorities } from "src/utils/form-elements";

import { FormElementFormProps } from "./form-element-form.component";
import { formElementFormValidation } from "./form-element-form.validation";

interface FormElementFormValues {
    id: string,
    title: string;
    canFill: boolean;
    canExpire: boolean;
    description: string;
    includeWithMessage: boolean;
    needsLegalReview: boolean;
    entityId: string;
    parentId: string;
    priority: PriorityType;
    dueDate: string;
}

const getFormattedDueDate = (dueDate: string): string => {
    return dueDate ? format(new Date(dueDate), 'dd/MM/yyyy') : null;
}

const getDueDateStatus = (dueDate: string): 'success' | 'warning' | 'danger' => {
    // if due date is due, return 'danger'
    // else if due date is within 7 days, return 'warning'
    // else return 'success'
    if (!dueDate) {
        return 'success';
    } else if (new Date(dueDate) < new Date()) {
        return 'danger';
    } else if (new Date(dueDate) < new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)) {
        return 'warning';
    } else {
        return 'success';
    }
};

const PriorityTypeList = {
    "Unset": "Unset",
    "UNSET": "UNSET",
    "HIDDEN": "HIDDEN",
    "PRIVATE": "PRIVATE",
    "IGNORE": "IGNORE",
    "BLOCKER": "BLOCKER",
    "LOW": "LOW",
    "MEDIUM": "MEDIUM",
    "HIGH": "HIGH",
    "CRITICAL": "CRITICAL",
    "URGENT": "URGENT"
}
const priorities = Object.keys(PriorityTypeList)
    .filter(filterPriority)
    .sort(sortPriorities);

export const useFormElementFormState = (props: FormElementFormProps) => {
    const elementState = useGetElements({ loanId: props.loanId });
    const conventionalElements = useGetLoanElementsQuery({
        id: props.loanId,
        view: 'CONVENTIONAL'
    })
    const router = useRouter();
    const dispatch = useDispatch();
    const [createElements] = useCreateElementsMutation();
    const [updateElements] = useUpdateElementsMutation();
    const [createSharedInfoElement] = useCreateSharedInfoElementMutation();
    const userState = useUser();
    const elementToEdit = conventionalElements.data?.list?.find(element => element.id === props.elementId);


    const initialValues: FormElementFormValues = elementToEdit ? {
        id: elementToEdit.id,
        title: elementToEdit.originalTitle,
        canFill: elementToEdit.modifiers.includes('FILLABLE_FORM'),
        canExpire: !!elementToEdit.canHaveExpiration,
        description: elementToEdit.description ?? "",
        includeWithMessage: elementToEdit.includeDescription,
        needsLegalReview: elementToEdit.modifiers.includes('NEEDS_LEGAL_REVIEW'),
        entityId: elementToEdit.sherpaEntityId ?? "PROJECT_DOCS",
        parentId: elementToEdit.parentId,
        priority: elementToEdit.priorityType,
        dueDate: elementToEdit.expireDate,
    } : null

    const onSubmit = async (values: FormElementFormValues, formikHelpers: FormikHelpers<FormElementFormValues>) => {
        formikHelpers.setSubmitting(true);
        const modifiers = []
        elementToEdit?.modifiers.forEach(modifier => {
            if (modifier !== 'FILLABLE_FORM' && modifier !== 'NEEDS_LEGAL_REVIEW') {
                modifiers.push(modifier);
            }
        });
        if (values.canFill) {
            modifiers.push('FILLABLE_FORM');
        }
        if (values.needsLegalReview) {
            modifiers.push('NEEDS_LEGAL_REVIEW');
        }
        try {
            const sherpaEntityId = (!!values.entityId && values.entityId !== "PROJECT_DOCS") ? values.entityId : null;
            if (values.id) {
                await updateElements({
                    elements: [{
                        id: values.id,
                        loanId: props.loanId,
                        title: values.title,
                        storageType: 'FILE',
                        description: values.description,
                        canHaveExpiration: !!values.canExpire,
                        expireDate: values.dueDate,
                        needsLegalReview: values.needsLegalReview,
                        includeDescription: values.includeWithMessage,
                        sherpaEntityId,
                        parentId: values.parentId,
                        priorityType: values.priority,
                        modifiers,
                    }],
                    multiSelect: false
                })
                // New File Request created on {{View/Folder name}} successfully
                toast.success(`${values.title} updated successfully`);
            } else {
                const createdElements = await createElements({
                    elements: [{
                        loanId: props.loanId,
                        title: values.title,
                        storageType: 'FILE',
                        description: values.description,
                        canHaveExpiration: !!values.canExpire,
                        expireDate: values.dueDate,
                        needsLegalReview: values.needsLegalReview,
                        includeDescription: values.includeWithMessage,
                        sherpaEntityId,
                        parentId: values.parentId,
                        priorityType: values.priority,
                        modifiers,
                    }],
                    multiSelect: false
                }).unwrap();
                if (userState.isBorrower) {
                    // if user is a borrower we need to create a share with borrower so he can view the file

                    // get first element from packageInfo
                    const packageInfo = createdElements.list[0];
                    await createSharedInfoElement({
                        shares: [{
                            id: null,
                            infoId: packageInfo.id,
                            loanId: props.loanId,
                            permissions: ['EDIT', 'VIEW'],
                            sharedByUserId: userState.user.id,
                            sharedWithUserId: userState.user.id,
                        }]
                    });
                }
                // navigate to created element
                router.push({
                    pathname: router.pathname,
                    query: {
                        ...router.query,
                        [QUERY_PARAM_FORM_ELEMENT_ID]: createdElements.list[0].id
                    }
                })
                // New File Request created on {{View/Folder name}} successfully
                toast.success(`New File Request created on ${values.title} successfully`);
            }
        } catch (error) {
            console.error(error);
        } finally {
            await dispatch(getLoanFormElements(props.loanId, true));
            formikHelpers.setSubmitting(false);
            props.onSuccess?.();
        }
    }

    const formik = useFormik<FormElementFormValues>({
        validationSchema: formElementFormValidation,
        enableReinitialize: true,
        initialValues: {
            id: '',
            title: '',
            description: '',
            canFill: false,
            canExpire: false,
            needsLegalReview: false,
            includeWithMessage: false,
            entityId: '',
            parentId: props.parentId,
            priority: null,
            dueDate: '',
            ...initialValues
        },
        onSubmit,
    });

    const handleCheckChange = (name: string, checked: boolean) => {
        formik.setFieldValue(name, checked);
    };

    const onDueDateSelected = (date: Date) => {
        formik.setFieldValue('dueDate', date);
    };

    const onEntitySelected = (entityId: string) => {
        formik.setFieldValue('entityId', entityId);
    };
    const onPackageInfoSelected = (packageInfoId: string) => {
        formik.setFieldValue('parentId', packageInfoId, true);
    }

    const onPrioritySelected = (priority: PriorityType) => {
        formik.setFieldValue('priority', priority);
    }

    const entities = [...props.loanEntities.map(entity => ({
        id: entity.sherpaEntity.id,
        name: entity.sherpaEntity.name,
    }))];
    // if we are editing and element does not have a sherpaEntityId, we need to
    // add the project docs entity to the list
    if (!elementToEdit || !elementToEdit.sherpaEntityId) {
        entities.push({
            id: 'PROJECT_DOCS',
            name: 'Project Docs'
        });
    }

    const openAccordionIds = useMemo(() => {
        const ids = formik.values.id
            ? findSectionByIdAndItsAncestors(formik.values.id, elementState.list)
                .map(element => element.id)
            : [];

        return [
            ...ids,
            elementState.tree[0]?.id
        ]
    }, [elementState.list, elementState.tree, formik.values.id]);

    return {
        onDueDateSelected,
        handleCheckChange,
        onEntitySelected,
        onPackageInfoSelected,
        onPrioritySelected,
        openAccordionIds,
        isLoading: elementState.isLoading || conventionalElements.isLoading,
        isSubmitting: formik.isSubmitting,
        priorities,
        formik,
        entities,
        descriptionLength: formik.values.description.length,
        foldersTree: conventionalElements?.data?.tree ?? [],
        dueDateStatus: getDueDateStatus(formik.values.dueDate),
        formattedDueDate: getFormattedDueDate(formik.values.dueDate),
    } as const;
};