import { j as e } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as c } from "react";
import { T as d } from "./Text-DyXxCJgw.js";
const l = c((t, a) => {
  const { active: i, children: r, hideText: s, ...o } = t;
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      ...o,
      ref: a,
      "data-ui": i ? "active" : "",
      className: "absolute px-4 z-splash items-center justify-center flex  opacity-0 w-0 h-0 data-active:w-full data-active:h-full data-active:opacity-100 inset-0 bg-blue-50 bg-opacity-50 hover:bg-opacity-40 overflow-hidden",
      children: [
        !s && /* @__PURE__ */ e.jsx(
          d,
          {
            variant: "blue",
            weight: "medium",
            size: "sm",
            as: "div",
            className: "p-2 rounded-md bg-blue-50",
            children: "DROP FILES HERE"
          }
        ),
        r
      ]
    }
  );
});
l.displayName = "DropHere";
export {
  l as D
};
