import { StorageType } from "src/backend";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";

import { DefaultConsolidatedView, FileConsolidatedView, FormElementConsolidatedView, ShoeboxItemConsolidatedView } from "./form-element-context.types";

interface FormElementState {
    needsListElementsIds: string[];
    needsListStagedElementsIds: string[];
    deletingId: string;
    templateId: string;
    isConfirmAppendAlertOpen: boolean;
    templateTargetFormElementId: string;
    assignElements: FormElementV2ResponseDtoExtended[]
    dirtyPreviewFile: File;
    editFileRequestId: string;
    deleteMenuAnchorEl: HTMLElement;
    assignIsOpen: boolean;
    consolidatedView: DefaultConsolidatedView
    | FileConsolidatedView
    | ShoeboxItemConsolidatedView
    | FormElementConsolidatedView
}

const formElementContextInitialState: FormElementState = {
    deletingId: null,
    needsListElementsIds: [],
    assignElements: [],
    needsListStagedElementsIds: [],
    isConfirmAppendAlertOpen: false,
    templateId: null,
    editFileRequestId: null,
    templateTargetFormElementId: null,
    dirtyPreviewFile: null,
    deleteMenuAnchorEl: null,
    assignIsOpen: false,
    consolidatedView: {
        dropType: "DEFAULT" as const,
        storageType: 'FOLDER',
        formElement: null
    }
}

enum Action {
    setDeletingId = 'setDeletingId',
    setTemplateId = 'setTemplateId',
    setEditFileRequestId = 'setEditFileRequestId',
    setTemplateTargetFormElementId = 'setTemplateTargetFormElementId',
    setDirtyPreviewFile = 'setDirtyPreviewFile',
    setDeleteMenuAnchorEl = 'setDeleteMenuAnchorEl',
    setAssignIsOpen = 'setAssignIsOpen',
    setConsolidatedView = 'setConsolidatedView',
    setNeedsListElementsIds = 'setNeedsListElementsIds',
    setConfirmAppendAlertOpen = 'setConfirmAppendAlertOpen',
    setNeedsListStagedElementsIds = 'setNeedsListStagedElementsIds',
    setAssignElements = 'setAssignElements'
}

type FormElementAction =
    | { type: Action.setDeletingId, payload: string }
    | { type: Action.setTemplateId, payload: string }
    | { type: Action.setTemplateTargetFormElementId, payload: string }
    | { type: Action.setDirtyPreviewFile, payload: File }
    | { type: Action.setEditFileRequestId, payload: string }
    | { type: Action.setDeleteMenuAnchorEl, payload: HTMLElement }
    | { type: Action.setAssignIsOpen, payload: boolean }
    | { type: Action.setConsolidatedView, payload: DefaultConsolidatedView }
    | { type: Action.setConsolidatedView, payload: FileConsolidatedView }
    | { type: Action.setConsolidatedView, payload: ShoeboxItemConsolidatedView }
    | { type: Action.setConsolidatedView, payload: FormElementConsolidatedView }
    | { type: Action.setNeedsListStagedElementsIds, payload: string[] }
    | { type: Action.setNeedsListElementsIds, payload: string[] }
    | { type: Action.setConfirmAppendAlertOpen, payload: boolean }
    | { type: Action.setAssignElements, payload: FormElementV2ResponseDtoExtended[] }


const formElementContextReducer = (state: FormElementState, action: FormElementAction): FormElementState => {

    switch (action.type) {
        case Action.setDeletingId:
            return {
                ...state,
                deletingId: action.payload
            }
        case Action.setConfirmAppendAlertOpen:
            return {
                ...state,
                isConfirmAppendAlertOpen: action.payload
            }
        case Action.setNeedsListElementsIds:
            return {
                ...state,
                needsListElementsIds: action.payload
            }
        case Action.setNeedsListStagedElementsIds:
            return {
                ...state,
                needsListStagedElementsIds: action.payload
            }
        case Action.setEditFileRequestId:
            return {
                ...state,
                editFileRequestId: action.payload
            }
        case Action.setTemplateId:
            return {
                ...state,
                templateId: action.payload
            }
        case Action.setTemplateTargetFormElementId:
            return {
                ...state,
                templateTargetFormElementId: action.payload
            }
        case Action.setDirtyPreviewFile:
            return {
                ...state,
                dirtyPreviewFile: action.payload
            }
        case Action.setDeleteMenuAnchorEl:
            return {
                ...state,
                deleteMenuAnchorEl: action.payload
            }
        case Action.setAssignIsOpen:
            return {
                ...state,
                assignIsOpen: action.payload
            }
        case Action.setConsolidatedView:
            return {
                ...state,
                consolidatedView: action.payload
            }
        case Action.setAssignElements:
            return {
                ...state,
                assignElements: action.payload
            }
        default:
            return state;
    }
}

export const FormElementContextReducer = {
    initialState: formElementContextInitialState,
    reducer: formElementContextReducer,
    action: Action
}