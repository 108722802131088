import Cancel from "@mui/icons-material/Cancel";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useState } from "react";
import { setZipPasswordCallback } from "src/slices/form-element";
import { useDispatch, useSelector } from "src/store";

export const ZipPasswordDialog = () => {
    const dispatch = useDispatch();

    const zipPasswordCallback = useSelector(state => state.formElement.zipPasswordCallback);
    const [password, setPassword] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }
    const handleSubmit = () => {
        zipPasswordCallback(password);
    }
    const handleClose = () => {
        zipPasswordCallback(null);
        dispatch(setZipPasswordCallback(null));
    }

    return <Dialog
        onClose={handleClose}
        open={!!zipPasswordCallback}
        maxWidth='sm'
        fullWidth>
        <DialogTitle>
            Enter password for zip file.
        </DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="zipPassword"
                label="Zip Password"
                fullWidth
                onChange={handleChange}
            />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button size='small' onClick={handleClose} variant='contained' color='error' startIcon={<Cancel fontSize='small' />}>
                Cancel
            </Button>
            <Button size='small' onClick={handleSubmit} variant='contained' color="success">Confirm</Button>
        </DialogActions>
    </Dialog>
}