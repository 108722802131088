import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from "react";
import { ZipFileAction } from 'src/constants/common';
import { useFetchUserPreferences } from 'src/hooks/use-fetch-user-prefrences';
import { setZipFileActionCallback } from "src/slices/ui";
import { useDispatch, useSelector } from "src/store";
import { ZIP_FILE_ACTION } from 'src/types/common';

import { DialogContainer } from '../common/dialog-container';

export const ZipDadActionDialog = () => {
    const [rememberChoice, setRememberChoice] = useState(false);
    const dispatch = useDispatch();
    const preferences = useFetchUserPreferences();
    const zipFileActionCallback = useSelector(state => state.ui.zipFileActionCallback);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const zipAction = (event.target as HTMLInputElement).value as ZIP_FILE_ACTION;
        if (rememberChoice) {
            preferences.onUpdate({ dadZipFileHandling: zipAction });
        }
        zipFileActionCallback(zipAction);
        dispatch(setZipFileActionCallback(null));
    };

    const handleChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setRememberChoice(checked);
    }

    const handleClose = () => {
        zipFileActionCallback(null);
        dispatch(setZipFileActionCallback(null));
    }

    return <DialogContainer open={!!zipFileActionCallback} onClose={handleClose}>
        <DialogTitle sx={{ textAlign: 'center' }}>Extract Zip File?</DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
            <FormControlLabel
                control={<Checkbox value={rememberChoice} onChange={handleChange} />}
                label="Remember this choice." />
        </DialogContent>
        <DialogContent sx={{ alignItems: 'center', justifyContent: 'center', gap: 4, display: 'flex' }}>
            <Button
                variant='contained'
                size='large'
                color='secondary'
                sx={{ minWidth: 160, }}
                value={ZipFileAction.IGNORE}
                onClick={handleClick}>
                No
            </Button>
            <Button
                variant='contained'
                size='large'
                color='primary'
                sx={{ minWidth: 160, }}
                value={ZipFileAction.EXTRACT}
                onClick={handleClick}>
                Yes
            </Button>
        </DialogContent>

    </DialogContainer>
}